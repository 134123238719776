import React from 'react';
import { Redirect } from 'react-router-dom';
import { PrimaryButton } from '@tradetrax/web-common';
import { AccountsTable } from './Accounts.table';
import { AccountsContext } from './Accounts.context';
import { Paths } from '../../navigate';

export function TradesPage() {
  const context = AccountsContext();
  const { hasPermission } = context;
  const canManageTrades = hasPermission('manage_subs_accounts');

  if (!canManageTrades) return <Redirect to={Paths.NotPermission} />;

  return (
    <>
      <div className="d-flex flex-row justify-content-between m-4">
        <h4 className="font-weight-bold">Trades</h4>
        {canManageTrades ? (
          <PrimaryButton onClick={() => context.controller.openNewAccount('sub')}>New Trade Account</PrimaryButton>
        ) : null}
      </div>
      <AccountsTable kind="trades" context={context} canManageAccounts={canManageTrades} />
    </>
  );
}
