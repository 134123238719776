import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { AppContext, Context } from './App.context';
import { history, Paths } from './navigate';
import { MiniAlerts, Dialog, LayoutContent, NotFound } from '@tradetrax/web-common';
import * as Layout from './layout';
import * as Pages from './pages';

function App({ user }) {
  const context = Context({ user });
  const { appState, modal, miniAlertsRef } = context;
  const expanded = appState.getIn(['options', 'expanded']);

  return (
    <AppContext.Provider value={context}>
      <Router history={history}>
        <Layout.SideBar expanded={expanded} />
        <Layout.TopBar expanded={expanded} />
        <LayoutContent expanded={expanded}>
          <Dialog controller={modal} />
          <MiniAlerts ref={miniAlertsRef} />
          <Switch>
            {/* <Route path={Paths.Home} exact render={() => <div>Home</div>} /> */}
            <Route path={Paths.Builders} exact component={Pages.BuildersPage} />
            <Route path={Paths.Trades} exact component={Pages.TradesPage} />
            <Route path={Paths.Tasks} component={Pages.TasksPage} />
            <Route path={Paths.CustomReport} component={Pages.AdminReportPage} />
            <Route path={Paths.NotPermission} component={NotFound} exact />
            <Route exact path="/token">
              <Redirect to={Paths.Tasks} />
            </Route>
            <Route exact path={Paths.Home}>
              <Redirect to={Paths.Tasks} />
            </Route>
            <Route>
              <Redirect to={Paths.Tasks} />
            </Route>
          </Switch>
          {/* <Layout.Footer /> */}
        </LayoutContent>
      </Router>
    </AppContext.Provider>
  );
}
export default App;
