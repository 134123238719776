import React from 'react';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { EMPTY_ROW, renderCell } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { TableRowDropDown } from '@tradetrax/web-common/lib/Table/RowDropdownAction';
import { MINIMUM_BATCH_SIZE, COL_WIDTHS, WIDTHS } from './Accounts.shared';

const EMPTY_STATE_PROPS = {
  builders: {
    icon: 'star',
    title: 'There are no Builder Acounts on the system',
    body: 'When a Builder Account is created it will appear here.',
  },
  trades: {
    icon: 'compress',
    title: 'There are no Trade Acounts on the system',
    body: 'When a Trade Account is created it will appear here.',
  },
};

export const AccountsTable = ({ kind, context }) => {
  const { state, controller, loaderRef, hasPermission } = context;
  const accounts = state.get(kind);
  const isLoading = state.get('isLoading');
  const totalCount = isLoading ? 10 : accounts.size;
  const maxCount = isLoading ? 10 : accounts.size;
  const canImpersonate = hasPermission('impersonate');
  const rowClassName = rowData => (!rowData || rowData === EMPTY_ROW ? 'loading' : '');
  const NoRows = () => (
    <EmptyStateCenterContainer top="250px">
      <EmptyState data-testid="gtl-empty-state" {...EMPTY_STATE_PROPS[kind]} />
    </EmptyStateCenterContainer>
  );
  const onTableAction = (action, account) => {
    if (action === 'impersonate') {
      controller.impersonate(account);
    }
  };

  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!accounts.get(index)}
        loadMoreRows={controller.loadMoreTasks}
        minimumBatchSize={MINIMUM_BATCH_SIZE}
        ref={loaderRef}
        rowCount={Math.min(maxCount, totalCount)}
        threshold={0}
      >
        {({ onRowsRendered, registerChild }) => (
          <div style={{ flex: '1 1 auto' }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="trx-table"
                  estimatedRowSize={80}
                  headerHeight={40}
                  height={height}
                  onRowClick={() => {}}
                  onRowsRendered={onRowsRendered}
                  overscanRowCount={0}
                  ref={registerChild}
                  rowCount={Math.min(maxCount, totalCount)}
                  rowClassName={({ index }) => rowClassName(accounts.get(index))}
                  rowGetter={({ index }) => accounts.get(index) || EMPTY_ROW}
                  rowHeight={80}
                  width={width}
                  noRowsRenderer={NoRows}
                >
                  <Column width={COL_WIDTHS.span} label="" dataKey="" />
                  <Column
                    label="Account Name"
                    dataKey="name"
                    width={width - WIDTHS}
                    cellRenderer={renderCell(({ cellData: accuntName }) => (
                      <div className="text-truncate">{accuntName}</div>
                    ))}
                  />
                  <Column
                    label="EMail"
                    dataKey="email"
                    width={COL_WIDTHS.email}
                    cellRenderer={renderCell(({ cellData: email }) => {
                      return <div className="text-truncate">{email}</div>;
                    })}
                  />
                  {canImpersonate ? (
                    <Column
                      label=""
                      dataKey=""
                      width={COL_WIDTHS.action}
                      cellRenderer={({ rowData }) => (
                        <TableRowDropDown onSelect={action => onTableAction(action, rowData)}>
                          <TableRowDropDown.IconItem icon="user-secret" eventKey="impersonate" label="Impersonate" />
                        </TableRowDropDown>
                      )}
                    />
                  ) : null}
                  <Column label="" dataKey="" width={COL_WIDTHS.action} />
                </Table>
              )}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
};
