import React from 'react';
import { useAppContext } from 'app/App.context';
import { TopHeader, UserInfoMenu } from '@tradetrax/web-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, Nav, Button } from 'react-bootstrap';

export function TopBar({ expanded }) {
  const { user, appController, logout } = useAppContext();
  const userName = `${user.get('firstName')} ${user.get('lastName')}`;
  const onMenuSelect = option => option === 'logout' && logout();

  return (
    <TopHeader sticky="top" expanded={expanded}>
      <Navbar.Brand
        as={Button}
        className="mr-auto"
        variant="link"
        onClick={e => appController.toggleOption('expanded')}
      >
        <FontAwesomeIcon icon="bars" className="mx-3" />
      </Navbar.Brand>
      <Nav className="ml-auto d-none">
        <Nav.Link as={Button} variant="link">
          <FontAwesomeIcon icon="gear" />
        </Nav.Link>
        <Nav.Link as={Button} variant="link" onClick={e => appController.toggleOption('notifications')}>
          <FontAwesomeIcon icon="bell" />
        </Nav.Link>
      </Nav>
      <UserInfoMenu name={userName} onSelect={onMenuSelect} />
    </TopHeader>
  );
}
