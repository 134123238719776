import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import * as actions from './Accounts.actions';

const emptyState = fromJS({
  accounts: [],
  builders: [],
  trades: [],
  isLoading: true,
});

export function AccountsContext() {
  const loaderRef = React.useRef();
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState, appContext);
  const { hasPermission } = appContext;

  return { state, controller, loaderRef, hasPermission };
}
