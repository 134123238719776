import React from 'react';

export const AppliedFilters = ({ filters }) => {
  return (
    <div className="report-filters mt-3 mb-4">
      {filters.map(filter => (
        <AppliedFilter filter={filter} key={filter.get('key')} />
      ))}
    </div>
  );
};

export const AppliedFilter = ({ filter }) => {
  const { Component, value, report_label } = filter.toObject();
  const format = Component.formatValueReport ?? Component.formatValue;

  return (
    <div className="mb-2">
      {report_label}: <strong>{format(value)}</strong>
    </div>
  );
};
