import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../theme';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useReportContext } from '../Report.context';

export const ReportFilters = ({}) => {
  const context = useReportContext();
  const { state, controller } = context;
  const filters = state.get('filters');
  const report = state.get('report');
  const expandedFilterId = state.get('expandedFilterId');
  const [selectedFilter, chooseFilter] = React.useState(null);
  const onAddFilter = () => {
    if (!selectedFilter) return;
    controller.addFilter(selectedFilter);
    chooseFilter(null);
  };

  const availableFilters = React.useMemo(() => {
    return (
      report?.Filters.reduce((available, value) => {
        if (!filters.find(filter => filter.get('_id') === value._id)) {
          return available.concat(value);
        }
        return available;
      }, []) || []
    );
  }, [report, filters]);

  if (!report) return null;

  return (
    <div>
      <label>Filters</label>
      <div className="d-flex mb-3">
        <Typeahead
          style={{ width: '300px' }}
          id="add-filter"
          placeholder="Choose Filter"
          options={availableFilters}
          labelKey={option => option.label}
          selected={selectedFilter ? [selectedFilter] : []}
          onChange={([selected]) => chooseFilter(selected)}
          clearButton
        />
        <AddFilterButton onClick={onAddFilter} disabled={!selectedFilter}>
          <FontAwesomeIcon icon="plus" />
        </AddFilterButton>
      </div>
      <SelectedFilters filters={filters} controller={controller} expandedFilterId={expandedFilterId} />
    </div>
  );
};

const SelectedFilters = ({ filters, expandedFilterId, controller }) => {
  if (filters.size === 0) return null;

  return (
    <FiltersWrapper>
      <div className="mt-n2 mb-3">
        <button onClick={() => controller.clearFilters()} className="btn btn-link p-0 font-size-14">
          Clear All Filters
        </button>
      </div>
      {filters.map(filter => {
        const FilterComponent = filter.get('Component');
        const Component = expandedFilterId === filter.get('_id') ? FilterComponent : FilterComponent.Collapsed;
        return <Component controller={controller} filter={filter} key={filter.get('_id')} />;
      })}
    </FiltersWrapper>
  );
};

const FiltersWrapper = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: -4px;
  margin-top: -1px;
  overflow: visible;
`;

const AddFilterButton = styled.button`
  display: flex;
  width: 39px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  color: ${colors.white};
  background-color: ${colors.blue300};
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px; /* 88.889% */
  letter-spacing: 0.1px;
  margin-left: 12px;

  &[disabled] {
    background-color: ${colors.gray200};
  }
`;
