export const Home = '/';

export const Builders = `${Home}builders`;
export const Trades = `${Home}trades`;

export const Accounts = `${Home}accounts`;
export const AccountDetails = `${Accounts}/:accountId`;
export const CreateAccount = `${Accounts}/create`;

export const Tasks = `${Home}tasks`;
export const TaskNew = `${Tasks}/new`;
export const TaskDelete = `${Tasks}/:taskId/delete`;

export const CustomReport = `${Home}custom-report`;

export const NotPermission = `${Home}not-permission`;
