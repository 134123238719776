import React from 'react';
import { SideBar, SideBarLink } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import { Paths } from '../navigate';

export const AdminSideBar = ({ expanded }) => {
  const appContext = useAppContext();
  const { hasPermission } = appContext;

  return (
    <SideBar expanded={expanded} companyName={'TradeTrax'}>
      {/* <SideBarLink to={Paths.Home} icon="table-columns" exact>
        Dashboard
      </SideBarLink> */}
      {hasPermission('gtl_tasks_management') ? (
        <SideBarLink to={Paths.Tasks} icon="wrench">
          Tasks
        </SideBarLink>
      ) : null}
      {hasPermission('manage_builders_accounts') ? (
        <SideBarLink to={Paths.Builders} icon="star">
          Builders
        </SideBarLink>
      ) : null}
      {hasPermission('manage_subs_accounts') ? (
        <SideBarLink to={Paths.Trades} icon="compress">
          Trades
        </SideBarLink>
      ) : null}
      {hasPermission('report_management') ? (
        <SideBarLink to={Paths.CustomReport} icon="chart-column">
          Custom Report
        </SideBarLink>
      ) : null}
    </SideBar>
  );
};
