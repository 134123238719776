import { markAsSync } from '../useController';
import { fromJS, Map } from 'immutable';

markAsSync(chooseReport);
export function chooseReport(state, report) {
  return state
    .set('report', report)
    .set('filters', fromJS([]))
    .set('data', fromJS([]))
    .set('appliedFilters', fromJS([]));
}

markAsSync(addFilter);
export function addFilter(state, Filter) {
  if (!Filter) return state;
  const filter = Map(Filter).set('value', fromJS(Filter.defaultValue));

  return state.update('filters', filters => filters.push(filter)).set('expandedFilterId', Filter._id);
}

markAsSync(clearFilters);
export function clearFilters(state) {
  return state.set('filters', fromJS([]));
}

markAsSync(removeFilter);
export function removeFilter(state, filter) {
  const filters = state.get('filters');
  const index = filters.findIndex(f => f.get('_id') === filter.get('_id'));

  if (index >= 0) {
    return state.set('filters', filters.remove(index));
  }

  return state;
}

markAsSync(toggleFilter);
export function toggleFilter(state, filter) {
  const isExpanded = state.get('expandedFilterId') === filter.get('_id');

  return state.set('expandedFilterId', isExpanded ? null : filter.get('_id'));
}

markAsSync(setFilterValue);
export function setFilterValue(state, filter, value, ...path) {
  const filters = state.get('filters');
  const index = filters.indexOf(filter);

  if (index >= 0) {
    return state.setIn(['filters', index, 'value', ...path], value);
  }

  return state;
}

markAsSync(setValue);
export function setValue(state, property, value) {
  return state.set(property, value);
}
