import styled from 'styled-components';
import { colors } from '../theme';

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding-left: 28px;
  padding-right: 28px;

  .report-filters {
  }

  .group-outter {
    border: 1px solid ${colors.gray};
    border-radius: 20px 20px 0px 0px;
    margin-bottom: 28px;

    .group-outter-header {
      background: ${colors.pearl};
      border-radius: 20px 20px 0px 0px;
      padding: 17px 34px;
    }

    .group-inner-header {
      display: flex;
      flex-direction: row;
      padding: 28px 34px 20px 34px;
      color: ${colors.gray400};
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.2px;
    }

    .group-inner {
      padding: 0px 34px 16px;
    }

    .group-inner-summary {
      display: flex;
      flex-direction: row;
    }

    .group-inner-body {
      margin-top: 8px;
    }
  }

  .cell-w250, .cell-w150 {
    display: flex;
    &.behind {
      color: ${colors.red};
    }

    &.ahead {
      color: ${colors.green400};
    }
  }

  .cell-w250 { width: 250px; }
  .cell-w150 { width: 150px; }
}`;
