import React from 'react';
import { EmptyState } from '../EmptyState';

export const ReportEmptyState = () => (
  <div className="d-flex flex-grow-1 flex-shrink-1 justify-content-center align-items-center mt-n5">
    <EmptyState
      title="No Custom Reports Generated"
      body="When a Custom Report is generated, it will appear here."
      icon="chart-column"
    />
  </div>
);
