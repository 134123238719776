import { fromJS, Map } from 'immutable';
import { markAsSync } from '@tradetrax/web-common/lib/useController';
import { gtlService } from 'services';

markAsSync(loadGTLReport);
export function loadGTLReport(state) {
  const filter = this.getFilters(state);

  gtlService
    .readTasks()
    .then(fromJS)
    .then(data => {
      if (!filter.trades) return data;
      return data.filter(t => filter.trades.includes(t.get('trade')));
    })
    .then(data => {
      const model = data
        .filter(t => t.get('trade') !== 'default')
        .groupBy(t => t.get('trade'))
        .toList()
        .map(group => {
          const first = group.first();
          const trade = first.get('trade');

          return Map({
            trade,
            tradeId: first.get('_id'),
            tasks: group,
          });
        });

      this.controller.dispatch([state => state.set('data', model)]);
    });

  return state.set('appliedFilters', state.get('filters'));
}
