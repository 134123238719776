import { Filters } from './GTL.filters';
import { GlobalTaskLibraryReport } from './GTL';

export const GLobalTaskLibraryByTrade = {
  Filters,
  filter: [],
  _id: 'global_task_library_by_trade',
  name: 'GLobal Task Library by Trades',
  Report: GlobalTaskLibraryReport,
  apply: controller => controller.loadGTLReport(),
};
