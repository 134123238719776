import { FilterTrade } from './GTL.filters.trade';

export const Filters = [
  {
    _id: 'select_trades',
    field: 'trades',
    label: 'By Trade',
    report_label: 'Trades',
    defaultValue: FilterTrade.defaultValue,
    Component: FilterTrade,
  },
];
