import React from 'react';
import { Redirect } from 'react-router-dom';
import { PrimaryButton } from '@tradetrax/web-common';
import { TasksTable } from './Tasks.table';
import { GTLContext } from './GTL.context';
import { Paths } from '../../navigate';

export function TasksPage() {
  const context = GTLContext();
  const { controller, appContext } = context;
  const { hasPermission } = appContext;
  const canManageGTL = hasPermission('gtl_tasks_management');

  if (!canManageGTL) return <Redirect to={Paths.NotPermission} />;

  return (
    <>
      <div className="d-flex flex-row justify-content-between m-4">
        <h4 className="font-weight-bold">Global Tasks Library</h4>
        {canManageGTL ? <PrimaryButton onClick={() => controller.openNewTask()}>New Task</PrimaryButton> : null}
      </div>
      <TasksTable context={context} canManageGTL={canManageGTL} />
    </>
  );
}
