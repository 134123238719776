export function pendoInit(user) {
  const env = process.env.NODE_ENV;
  const pre = env === 'production' ? '' : env.substring(0, 3);
  if (user.domain === 'admin' || user.domain === 'homeowner') {
    user.accountId = user.email;
    user.accountName = `${user.domain}_${user.email}`;
  }
  window.pendo.initialize({
    visitor: {
      id: `${pre}_${user._id}`,
      email: user.email,
      full_name: `${user.firstName} ${user.lastName}`,
      role: user.role,
      domain: user.domain,
    },

    account: {
      id: `${pre}_${user.accountId}`,
      name: `${pre}_${user.accountName}`,
    },
  });
}
