export const MINIMUM_BATCH_SIZE = 25;

const REM = x => x * 16;

export const COL_WIDTHS = {
  span: REM(2),
  // accountkName: REM(20), grow to full table
  email: REM(20),
  action: REM(6),
};

export const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);
