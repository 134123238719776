import { fromJS, Map, Repeat } from 'immutable';
import { markAsSideEffect, markAsSync, EMPTY_ROW } from '@tradetrax/web-common/lib/useController';
import { DeleteDialog } from '@tradetrax/web-common/lib/Dialog/Dialog';
import { gtlService } from 'services';
import { TaskForm } from './Task.form';

export function readGTL() {
  return gtlService
    .readTasks()
    .then(fromJS)
    .then(tasks => () => tasks)
    .catch(err => {
      this.addAlert('There was an error trying to read the GTL!', 'danger');
      throw err;
    });
}

markAsSync(loadMoreTasks);
export function loadMoreTasks(state, { startIndex, stopIndex }) {
  // NOTE: Pagination not yet supported on GTL.

  gtlService.readTasks({}, {}).then(data => {
    const globalTasks = fromJS(data);
    this.controller.dispatch([
      state =>
        state
          .setIn(['totalCount'], data.length) //totalCount)
          .setIn(['maxCount'], data.length) // stopIndex + MINIMUM_BATCH_SIZE + 1)
          .updateIn(['tasks'], tasks => tasks.splice(startIndex, stopIndex - startIndex + 1, ...globalTasks.toArray())),
    ]);
  });

  const data = Repeat(EMPTY_ROW, stopIndex - startIndex);
  return state.updateIn(['tasks'], tasks =>
    tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray())
  );
}

markAsSideEffect(openNewTask);
export function openNewTask() {
  const title = 'Create new task';
  const handleSave = this.controller.createTask;
  this.modal.open(TaskForm, { title, handleSave });
}

markAsSideEffect(openEditTask);
export function openEditTask(task) {
  const title = 'Task Edit';
  const handleSave = this.controller.editTask;
  this.modal.open(TaskForm, { title, task, handleSave });
}

markAsSideEffect(createTask);
export async function createTask(form) {
  if (!form) return;

  return gtlService
    .createTask(form) // { name, trade }
    .then(({ _id }) => Map({ _id, ...form }))
    .then(task => {
      this.controller.dispatch([
        state =>
          state
            .update('tasks', tasks => tasks.unshift(task))
            .update('totalCount', totalCount => totalCount + 1)
            .update('maxCount', maxCount => maxCount + 1),
      ]);
      return task;
    })
    .catch(err => {
      this.addAlert('There was an error trying to create the task!', 'danger');
      throw err;
    });
}

markAsSideEffect(editTask);
export async function editTask(form, task) {
  const taskId = task.get('_id');
  try {
    await gtlService.updateTask(form, { params: { taskId } });
    this.controller.dispatch([
      state => {
        const tasks = state.get('tasks');
        const index = tasks.indexOf(task);
        return state.setIn(['tasks', index], task.merge(form));
      },
    ]);
  } catch (err) {
    this.addAlert('There was an error updating the task!', 'danger');
  }
}

markAsSideEffect(deleteTask);
export async function deleteTask(task) {
  const { isAccept } = await this.modal.open(DeleteDialog, {
    title: 'Delete Task',
    message: `Are you sure you want to delete the Task ${task.get('name')}?`,
  });

  if (!isAccept) return;
  const taskId = task.get('_id');
  try {
    await gtlService.deleteTask({}, { params: { taskId } });
    this.controller.dispatch([
      state => {
        const tasks = state.get('tasks');
        const index = tasks.indexOf(task);
        return state
          .deleteIn(['tasks', index])
          .update('totalCount', totalCount => totalCount - 1)
          .update('maxCount', maxCount => maxCount - 1);
      },
    ]);
    this.addAlert('The task was deleted.', 'success');
  } catch (err) {
    this.addAlert('There was an error deleting the task!', 'danger');
  }
}
