export const MINIMUM_BATCH_SIZE = 25;

const REM = x => x * 16;

export const COL_WIDTHS = {
  span: REM(2),
  // taskName: REM(20), grow to full table
  trade: REM(20),
  action: REM(6),
};

export const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);
