import { fromJS } from 'immutable';

export const emptyState = fromJS({
  name: '',
  report: null,
  chart: null,
  xAxis: null,
  yAxis: null,
  filters: [],
  appliedFilters: [],
  expandedFilterId: null,
  data: [],
});
