import React from 'react';
import { Redirect } from 'react-router-dom';
import { Context } from '@tradetrax/web-common/lib/Reports/Report.context';
import { ReportPage } from '@tradetrax/web-common/lib/Reports/Report.page';
import { useAppContext } from 'app/App.context';
import { gtlService } from 'services';
import { Paths } from 'app/navigate';

import * as gtlActions from './GTL/GTL.actions';
import { GLobalTaskLibraryByTrade } from './GTL';

const reportActions = { ...gtlActions };
const reportTypes = [GLobalTaskLibraryByTrade];

export const AdminReportPage = () => {
  const appContext = useAppContext();
  const context = Context(reportActions, gtlService, appContext);
  const { hasPermission } = appContext;

  if (!hasPermission('report_management')) return <Redirect to={Paths.NotPermission} />; //NoPermissions page is missing.
  return <ReportPage context={context} reportTypes={reportTypes} />;
};
