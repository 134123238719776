import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../theme';
import { FilterToolButton } from './Report.filters.buttons';

export const CollapsedFilter = ({ filter, controller, children }) => {
  return (
    <CollapsedFilterBody>
      {children}
      <FilterToolButton className="ml-auto" onClick={() => controller.toggleFilter(filter)}>
        <FontAwesomeIcon icon="chevron-down" />
      </FilterToolButton>
    </CollapsedFilterBody>
  );
};

const CollapsedFilterBody = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  gap: 8px;
  padding: 13px 13px 13px 20px;
  border-radius: 10px;
  background: ${colors.white};
  margin-bottom: 8px;

  span.filter-label {
    color: ${colors.gray800};
    /* Bold 14 */
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  span.filter-value {
    color: ${colors.gray800};
    /* Regular 14 */
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
`;
