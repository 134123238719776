import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import * as actions from './GTL.actions';
import { useAppContext } from 'app/App.context';

const emptyState = fromJS({
  tasks: [],
  totalCount: 10,
  maxCount: 10,
});

export function GTLContext() {
  const loaderRef = React.useRef();
  const appContext = useAppContext();
  const [state, controller] = useController(actions, emptyState, { ...appContext, loaderRef });

  return { state, controller, loaderRef, appContext };
}
