import { fromJS, Map, Repeat } from 'immutable';
import { accountsService, usersService } from 'services';
import { markAsSideEffect, markAsSync, EMPTY_ROW } from '@tradetrax/web-common/lib/useController';
import { AccountForm } from './Account.form';

function spreadAccounts(accounts) {
  const builders = accounts.filter(a => a.get('type') === 'builder');
  const trades = accounts.filter(a => a.get('type') === 'sub');
  return { accounts, builders, trades };
}

markAsSync(loadMoreTasks);
export function loadMoreTasks(state, { startIndex, stopIndex }) {
  // NOTE: Pagination not yet supported on GTL.

  accountsService
    .viewAccounts()
    .then(fromJS)
    .then(spreadAccounts)
    .then(result => {
      this.controller.dispatch([state => state.merge(result).set('isLoading', false)]);
    });

  const data = Repeat(EMPTY_ROW, stopIndex - startIndex);
  return state
    .updateIn(['builders'], tasks => tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()))
    .updateIn(['trades'], tasks => tasks.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()));
}

export function readAccounts() {
  return accountsService
    .viewAccounts()
    .then(fromJS)
    .then(spreadAccounts)
    .then(result => state => state.merge(result))
    .catch(err => {
      const message = (
        <>
          There was an error reading the accounts!
          <button className="btn btn-link mx-3" onClick={() => this.controller.readAccounts()}>
            try again
          </button>
        </>
      );
      this.addAlert(message, 'danger');
      throw err;
    });
}
markAsSideEffect(openNewAccount);
export function openNewAccount(type) {
  const title = `Create new ${type} account`;
  this.modal.open(AccountForm, { title, type, controller: this.controller });
}

markAsSideEffect(newAccount);
export async function newAccount(form, type) {
  if (!form) return;

  return accountsService
    .createAccount({ ...form, type })
    .then(({ accountId }) => {
      const account = Map({
        type,
        name: form.name,
        email: form.email,
        _id: accountId,
      });

      this.controller.dispatch([
        state => {
          const accounts = state.get('accounts').push(account);
          return state.merge(spreadAccounts(accounts));
        },
      ]);

      this.addAlert(`${type} Account Created, the user will receive a confirmation email`);
    })
    .catch(err => {
      this.addAlert('There was an error creating the Account, please try again.', 'danger');
      throw err;
    });
}

markAsSideEffect(impersonate);
export function impersonate(account) {
  const email = account.get('email');
  usersService
    .impersonate({ email })
    .then(response => {
      const { domain } = response;
      const host = window.location.hostname.replace(/^admin./, `${domain}.`);
      const { protocol } = window.location;
      const url = `${protocol}//${host}`;
      window.location.replace(url);
    })
    .catch(err => {
      this.addAlert('There was an error trying to impersonate this account!', 'danger');
      throw err;
    });
}
