import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Map } from 'immutable';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { PrimaryButton } from '@tradetrax/web-common';

const newAccount = Map({ name: '', trade: '' });
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Account Name is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email()
    .required('Email is required'),
});

export const AccountForm = ({ title, type, controller, account = newAccount, accept, close }) => {
  const { register, handleSubmit, errors, formState /*,  reset */ } = useForm({
    account,
    validationSchema,
    resolver: yupResolver(validationSchema),
  });
  const isNew = account === newAccount;

  const onSave = handleSubmit(form => {
    controller
      .newAccount(form, type)
      .then(() => accept())
      .catch(err => {
        console.log('error !!!', err);
      });
  });

  return (
    <Modal show={true} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form validated={formState.isValid} onSubmit={onSave}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Account Name</Form.Label>
            <Form.Control
              type="text"
              size="lg"
              name="name"
              ref={register}
              placeholder="Name"
              defaultValue={account.get('name')}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Manager First Name</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="firstName"
              ref={register}
              placeholder="First Name"
              defaultValue={account.get('firstName')}
              isInvalid={!!errors.firstName}
            />
            <Form.Control.Feedback type="invalid">{errors.firstName && errors.firstName.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Manager Last Name</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="lastName"
              ref={register}
              placeholder="Last Name"
              defaultValue={account.get('lastName')}
              isInvalid={!!errors.lastName}
            />
            <Form.Control.Feedback type="invalid">{errors.lastName && errors.lastName.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Manager Email</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="email"
              ref={register}
              placeholder="Email"
              defaultValue={account.get('email')}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email && errors.email.message}</Form.Control.Feedback>
          </Form.Group>
          <Modal.Footer className="mt-4 pt-1 px-0">
            <PrimaryButton variant="secondary" onClick={() => close()}>
              Cancel
            </PrimaryButton>
            <PrimaryButton type="submit">{isNew ? 'Create' : 'Update'}</PrimaryButton>
          </Modal.Footer>
        </Modal.Body>
      </Form>
    </Modal>
  );
};
