import React from 'react';
import { Redirect } from 'react-router-dom';
import { PrimaryButton } from '@tradetrax/web-common';
import { AccountsTable } from './Accounts.table';
import { AccountsContext } from './Accounts.context';
import { Paths } from '../../navigate';

export function BuildersPage() {
  const context = AccountsContext();
  const { hasPermission } = context;
  const canManageBuilders = hasPermission('manage_builders_accounts');

  if (!canManageBuilders) return <Redirect to={Paths.NotPermission} />;

  return (
    <>
      <div className="d-flex flex-row justify-content-between m-4">
        <h4 className="font-weight-bold">Builders</h4>
        {canManageBuilders ? (
          <PrimaryButton onClick={() => context.controller.openNewAccount('builder')}>
            New Builder Account
          </PrimaryButton>
        ) : null}
      </div>
      <AccountsTable kind="builders" context={context} canManageAccounts={canManageBuilders} />
    </>
  );
}
