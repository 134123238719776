import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../theme';
import { default as Datepicker } from '../../Datepicker';
import { RemoveFilterButton, FilterToolButton } from './Report.filters.buttons';
import { useReportContext } from '../Report.context';
import { CollapsedFilter } from './Report.filters.collapsed';

export const DateRange = ({ filter }) => {
  const context = useReportContext();
  const { controller } = context;
  const { from, to } = filter.get('value').toObject();
  const setDate = property => date => controller.setFilterValue(filter, date, property);

  return (
    <FilterContainer>
      <div className="d-flex mb-3 align-items-center">
        <span className="title">{filter.get('label')}</span>
        <RemoveFilterButton remove={() => controller.removeFilter(filter)} />
        <FilterToolButton onClick={() => controller.toggleFilter(filter)}>
          <FontAwesomeIcon icon="chevron-up" />
        </FilterToolButton>
      </div>
      <div className="d-flex flex-row">
        <div className="filter-time-panel">
          <div style={{ width: '130px' }}>
            <span className="label">From</span>
            <Datepicker onChange={setDate('from')} selected={from} name={`${filter.get('field')}_from`}></Datepicker>
          </div>
        </div>
        <div className="filter-time-panel">
          <div style={{ width: '130px' }}>
            <span className="label">To</span>
            <Datepicker onChange={setDate('to')} selected={to} name={`${filter.get('field')}_to`}></Datepicker>
          </div>
        </div>
      </div>
    </FilterContainer>
  );
};

DateRange.Collapsed = function({ filter, controller }) {
  const Component = filter.get('Component');
  const value = filter.get('value');

  return (
    <CollapsedFilter filter={filter} controller={controller}>
      <div className="d-flex flex-column">
        <span className="filter-label mb-2">{filter.get('label')}</span>
        <span className="filter-value">{Component.formatValue(value)}</span>
      </div>
    </CollapsedFilter>
  );
};

const DATE_FORMAT = 'MMM DD, YYYY';

DateRange.formatValue = value => {
  const from = value?.get('from');
  const to = value?.get('to');

  if (from && to) {
    return `${moment(from).format(DATE_FORMAT)} to ${moment(to).format(DATE_FORMAT)}`;
  }

  if (from) {
    return `from ${moment(from).format(DATE_FORMAT)}`;
  }

  if (to) {
    return `to ${moment(to).format(DATE_FORMAT)}`;
  }

  return 'No dates selected';
};

const ISO = 'YYYY-MM-DD';

DateRange.queryValue = value => {
  const from = value?.get('from');
  const to = value?.get('to');

  if (!from && !to) return null;

  const result = {};
  if (from) {
    result.from = moment(from).format(ISO);
  }

  if (to) {
    result.to = moment(to).format(ISO);
  }

  return result;
};

DateRange.defaultValue = {
  from: null,
  to: null,
};

const FilterContainer = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  border-radius: 10px;
  padding: 13px 13px 20px 20px;
  display: flex;
  flex-direction:  column;
  margin-bottom: 8px;

  .filter-time-panel {
    display: flex;
    flex-direction column;
    width: 50%;
  }

  .title {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .label {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
`;
