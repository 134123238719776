import React from 'react';
import { isFunction } from 'lodash';
import { useController } from '../useController';
import { emptyState } from './Report.emptyState';
import * as commonActions from './Report.actions';

export const ReportContext = React.createContext(null);
export const useReportContext = () => React.useContext(ReportContext);

export function Context(reportActions, service, appContext) {
  const actions = React.useMemo(
    () => ({
      ...commonActions,
      ...reportActions,
    }),
    [reportActions]
  );

  const [state, controller] = useController(actions, emptyState, { service, getFilters, ...appContext });

  return { state, controller, service };
}

const getFilters = state =>
  state.get('filters').reduce((reduction, filter) => {
    let { field, value, Component } = filter.toObject();

    if (isFunction(Component.queryValue)) {
      value = Component.queryValue(value);
    }

    if (value) {
      reduction[field] = value;
    }

    return reduction;
  }, {});
