import React from 'react';
import { fromJS } from 'immutable';
import { usersService } from '../services';
import { DialogController, redirectToLogin } from '@tradetrax/web-common';
import { AppContext, useAppContext } from '@tradetrax/web-common/lib/globalAppContext';
import { useController } from '@tradetrax/web-common/lib/useController';
import * as actions from './App.actions';

export { AppContext, useAppContext };

const emptyState = fromJS({
  options: {
    expanded: true,
    notifications: false,
  },
});

export function Context({ user }) {
  const adminUser = React.useMemo(() => fromJS(user), [user]);
  const modal = DialogController();
  const miniAlertsRef = React.useRef();
  const alert = miniAlertsRef.current;
  const addAlert = _addAlert(miniAlertsRef);
  const [appState, appController] = useController(actions, emptyState, {
    modal,
    alert,
    miniAlertsRef,
    addAlert,
  });

  const hasPermission = (key, value = 'yes') => adminUser.getIn(['permissions', key]) === value;
  const getPermission = key => adminUser.getIn(['permissions', key]);

  return {
    appState,
    appController,
    modal,
    user: adminUser,
    logout,
    alert,
    miniAlertsRef,
    addAlert,
    hasPermission,
    getPermission,
  };

  function logout() {
    return usersService.logout().then(() => redirectToLogin(false));
  }
}

function _addAlert(alertRef) {
  return function(message, variant = 'success', defer = 100) {
    setTimeout(() => {
      const alert = alertRef.current;
      if (alert) {
        alert.add({ message, variant });
      }
    }, defer);
  };
}
