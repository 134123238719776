import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import { SecondaryButton } from '../../Button';
import { colors } from '../../theme';

export const RemoveFilterButton = ({ remove }) => {
  const [show, setShow] = React.useState(false);
  const onRemove = () => {
    setShow(false);
    remove();
  };

  const popover = (
    <NoArrowPopOver id="popover-remove-filter" content={true} style={{ maxWidth: 350, borderRadius: 8 }}>
      <NoArrowPopOver.Content>
        <p>Remove filter. You can add it again later.</p>
        <div className="d-flex flex-row  justify-content-end">
          <Button variant="link" className="text-secondary font-weight-bold mr-3" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <SecondaryButton variant="outline-primary" onClick={onRemove}>
            Remove
          </SecondaryButton>
        </div>
      </NoArrowPopOver.Content>
    </NoArrowPopOver>
  );

  React.useEffect(() => {
    const isInsidePoper = node => {
      if (!node) return false;
      if (node.id === 'popover-remove-filter') return true;
      return isInsidePoper(node.parentElement);
    };

    const onClick = e => {
      if (show) {
        if (isInsidePoper(e.target)) return;
        e.preventDefault();
        e.stopPropagation();
        setShow(false);
      }
    };

    global.document.addEventListener('click', onClick);
    return () => {
      global.document.removeEventListener('click', onClick);
    };
  }, [show, setShow]);

  return (
    <OverlayTrigger trigger="click" placement="right-start" show={show} onToggle={setShow} overlay={popover}>
      <FilterToolButton className={cn('ml-auto', { 'mini-modal-open': show })} title="Delete Filter">
        <FontAwesomeIcon icon="trash" />
      </FilterToolButton>
    </OverlayTrigger>
  );
};

const NoArrowPopOver = styled(Popover)`
  .arrow {
    display: none;
  }
`;

export const FilterToolButton = styled.button`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  color: ${colors.gray400};
  background-color: ${colors.white};
  &.mini-modal-open {
    color: ${colors.blue300} !important;
  }
`;
