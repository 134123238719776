import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { usersService } from './services';
import { redirectToLogin } from '@tradetrax/web-common';
import { pendoInit } from '@tradetrax/web-common/lib/pendo';
import '@tradetrax/web-common/scss/_tradetrax.scss';
import '@tradetrax/web-common/lib/font-awesome';
import './pendo.js';

const { pathname, href } = window.location;
if (/^\/token\/.+/.test(pathname)) {
  const token = pathname.substr(7);
  usersService
    .login({ token })
    .catch(() => window.location.replace(href.replace(/\/\/builder\./, '//my.')))
    .then(start);
} else {
  usersService
    .info()
    .catch(() => redirectToLogin())
    .then(start);
}

function start(user) {
  if (user.domain !== 'admin') return redirectToLogin();
  pendoInit(user);
  document.cookie = 'userType=admin;domain=tradetrax.com';
  ReactDOM.render(<App user={user} />, document.getElementById('wrapper'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
