import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Map } from 'immutable';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { PrimaryButton } from '@tradetrax/web-common';

const newTask = Map({ name: '', trade: '' });
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Task name is required'),
  trade: Yup.string().required('Trade is required'),
});

export const TaskForm = ({ handleSave, title, task = newTask, accept, close }) => {
  const { register, handleSubmit, formState, errors /*, reset */ } = useForm({
    task,
    resolver: yupResolver(validationSchema),
  });
  const isNew = task === newTask;

  const onSave = handleSubmit(form => {
    handleSave(form, task)
      .then(() => accept())
      .catch(err => {
        console.log('error !!!', err);
      });
  });

  return (
    <Modal show={true} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form validated={formState.isValid} onSubmit={onSave}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Task Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              size="lg"
              ref={register}
              placeholder="Name"
              defaultValue={task.get('name')}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Trade</Form.Label>
            <Form.Control
              type="text"
              name="trade"
              size="lg"
              ref={register}
              placeholder="Trade"
              isInvalid={!!errors.trade}
              defaultValue={task.get('trade')}
            />
            <Form.Control.Feedback type="invalid">{errors.trade && errors.trade.message}</Form.Control.Feedback>
          </Form.Group>
          <Modal.Footer className="mt-4 pt-1 px-0">
            <PrimaryButton variant="secondary" onClick={() => close()}>
              Cancel
            </PrimaryButton>
            <PrimaryButton type="submit">{isNew ? 'Create New' : 'Update'} Task</PrimaryButton>
          </Modal.Footer>
        </Modal.Body>
      </Form>
    </Modal>
  );
};
