import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { colors } from '../theme';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ReportFilters } from './Filters/Report.filters';
import { ReportEmptyState } from './Report.Empty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReportContext } from './Report.context';

const NullReport = {
  Filters: [],
  filter: [],
  _id: 'null',
  name: 'no report selected',
  apply: () => {},
  Report: () => <ReportEmptyState />,
};

export const ReportPage = ({ context, reportTypes }) => {
  const [isCollapsed, setCollapsed] = React.useState(false);
  const { state, controller } = context;
  const { report, chart, xAxis, yAxis } = state.toObject();
  const selectedReport = report || NullReport;
  const setValue = property => ([value]) => {
    controller.setValue(property, value);
  };

  const loadReport = e => {
    e.preventDefault();
    e.stopPropagation();
    selectedReport.apply(controller);
  };

  const toggleCollapsed = () => {
    setTimeout(() => {
      setCollapsed(!isCollapsed);
    }, 1);
  };

  return (
    <ReportContext.Provider value={context}>
      <Container>
        <SidePanel className={cn({ collapsed: isCollapsed })}>
          <div className="d-flex flex-row align-items-center mb-3">
            <h4 className={cn('font-weight-bold', { 'd-none': isCollapsed })}>Custom Report</h4>
            <button
              className={cn('btn btn-link ml-auto text-secondary', { 'mr-n3': !isCollapsed })}
              onClick={toggleCollapsed}
            >
              <FontAwesomeIcon icon={`arrow-${isCollapsed ? 'right' : 'left'}-to-line`} />
            </button>
          </div>
          <span>Report</span>
          <Typeahead
            id="select-report"
            placeholder="Choose Report"
            options={reportTypes}
            labelKey={option => option.name}
            selected={report ? [report] : []}
            onChange={([report]) => controller.chooseReport(report)}
            clearButton
          />
          <hr />
          <ChartType report={report} chart={chart} setValue={setValue} />
          <XAxis report={report} xAxis={xAxis} setValue={setValue} />
          <YAxis report={report} yAxis={yAxis} setValue={setValue} />
          <hr />
          <ReportFilters />
          <GenerateReportButton disabled={selectedReport === NullReport} onClick={loadReport}>
            Generate Report
          </GenerateReportButton>
        </SidePanel>

        <ReportContainer>
          <selectedReport.Report context={context} /> {/* <Viewer ref={viewerRef} /> */}
        </ReportContainer>
      </Container>
    </ReportContext.Provider>
  );
};

function ChartType({ report, chart, setValue }) {
  if (!report?.chartTypes) return null;
  return (
    <>
      <span>Chart Type</span>
      <Typeahead
        id="select-chart"
        placeholder="Choose Chart Type"
        options={report?.chartTypes ?? []}
        labelKey={option => option.name}
        selected={chart ? [chart] : []}
        onChange={setValue('chart')}
      />
    </>
  );
}

function XAxis({ report, xAxis, setValue }) {
  if (!report?.xAxis) return null;
  return (
    <>
      <span>X - Axis</span>
      <Typeahead
        id="select-x-axis"
        placeholder="Trade Companies"
        options={report?.xAxis ?? []}
        labelKey={option => option.name}
        selected={xAxis ? [xAxis] : []}
        onChange={setValue('xAxis')}
      />
    </>
  );
}

function YAxis({ report, yAxis, setValue }) {
  if (!report?.yAxis) return null;
  return (
    <>
      <span>Y - Axis</span>
      <Typeahead
        id="select-xy-axis"
        placeholder="Number of Tasks"
        options={report?.yAxis ?? []}
        labelKey={option => option.name}
        selected={yAxis ? [yAxis] : []}
        onChange={setValue('yAxis')}
      />
    </>
  );
}
const ReportContainer = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
  display: flex;
  position: relative;
  overflow: auto;
`;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: stretch;
`;

const SidePanel = styled.div`
  transition: width 600ms ease;
  display: flex;
  background-color: ${colors.pearl};
  flex-direction: column;
  padding: 40px 30px 40px 20px;
  border-radius: 0px 20px 20px 0px;
  border: 1px solid ${colors.gray};
  box-shadow: 0px 2px 10px 0px rgba(51, 51, 51, 0.12);
  width: 410px;

  &:not(.collapsed) {
    min-width: 410px;
  }

  &.collapsed {
    width: 66px;
    padding: 40px 12px 40px 12px;
    > * {
      display: none;
    }
  }
`;

const GenerateReportButton = styled.button`
  width: 340px;
  display: flex;
  margin-top: 3rem;
  padding: 14px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 30px;
  color: ${colors.white};
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  // margin-top: auto;

  background-color: ${colors.blue300};
  &:not(:disabled):hover {
    background-color: ${colors.blue400};
  }

  /* Deactivated */
  &:disabled {
    box-shadow: 0px 1px 2px 0px #cecdd2 inset;
    background-color: ${colors.gray200};
  }
`;
