import React from 'react';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { EMPTY_ROW, renderCell } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { TableRowDropDown } from '@tradetrax/web-common/lib/Table/RowDropdownAction';
import { MINIMUM_BATCH_SIZE, COL_WIDTHS, WIDTHS } from './GTL.shared';

export const TasksTable = ({ context, canManageGTL }) => {
  const { controller, state, loaderRef } = context;
  const { tasks, totalCount, maxCount } = state.toObject();

  const rowClassName = rowData => (!rowData || rowData === EMPTY_ROW ? 'loading' : '');
  const NoRows = () => (
    <EmptyStateCenterContainer top="250px">
      <EmptyState
        icon="wrench"
        title="No Tasks on GTL"
        body="When a task is not started or still in progress it will appear here."
        data-testid="gtl-empty-state"
      />
    </EmptyStateCenterContainer>
  );
  const onTableAction = (action, task) => {
    console.log({ action, task });
    if (action === 'remove-task') {
      controller.deleteTask(task);
    } else if (action === 'rename-task') {
      controller.openEditTask(task);
    }
  };

  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!tasks.get(index)}
        loadMoreRows={controller.loadMoreTasks}
        minimumBatchSize={MINIMUM_BATCH_SIZE}
        ref={loaderRef}
        rowCount={Math.min(maxCount, totalCount)}
        threshold={0}
      >
        {({ onRowsRendered, registerChild }) => (
          <div style={{ flex: '1 1 auto' }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="trx-table"
                  estimatedRowSize={80}
                  headerHeight={40}
                  height={height}
                  onRowClick={() => {}}
                  onRowsRendered={onRowsRendered}
                  overscanRowCount={0}
                  ref={registerChild}
                  rowCount={Math.min(maxCount, totalCount)}
                  rowClassName={({ index }) => rowClassName(tasks.get(index))}
                  rowGetter={({ index }) => tasks.get(index) || EMPTY_ROW}
                  rowHeight={80}
                  width={width}
                  noRowsRenderer={NoRows}
                >
                  <Column width={COL_WIDTHS.span} label="" dataKey="" />
                  <Column
                    label="Task Name"
                    dataKey="name"
                    width={width - WIDTHS}
                    cellRenderer={renderCell(({ cellData: taskName, rowData: task, rowIndex }) => (
                      <div className="text-truncate">{taskName}</div>
                    ))}
                  />
                  <Column
                    label="Trade"
                    dataKey="trade"
                    width={COL_WIDTHS.trade}
                    cellRenderer={renderCell(({ cellData: trade }) => {
                      return <div className="text-truncate">{trade}</div>;
                    })}
                  />
                  {canManageGTL ? (
                    <Column
                      label=""
                      dataKey=""
                      width={COL_WIDTHS.action}
                      cellRenderer={({ rowData }) => (
                        <TableRowDropDown onSelect={action => onTableAction(action, rowData)} removeTask renameTask />
                      )}
                    />
                  ) : null}
                  {/* <Column label="" dataKey="" width={COL_WIDTHS.action} /> */}
                </Table>
              )}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
};
