import React from 'react';
import styled from 'styled-components';
import { fromJS, List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common/lib/theme';
import { Typeahead } from 'react-bootstrap-typeahead';
import { RemoveFilterButton, FilterToolButton } from '@tradetrax/web-common/lib/Reports/Filters/Report.filters.buttons';
import { useReportContext } from '@tradetrax/web-common/lib/Reports/Report.context';
import { CollapsedFilter } from '@tradetrax/web-common/lib/Reports/Filters/Report.filters.collapsed';

export const FilterTrade = ({ filter }) => {
  const context = useReportContext();
  const { controller, service } = context;
  const selectedTrades = filter.get('value').toArray();
  const setTrade = value => controller.setFilterValue(filter, List(value));
  const trades = useTrades(service);

  return (
    <FilterContainer>
      <div className="d-flex mb-3 align-items-center">
        <span className="title">{filter.get('label')}</span>
        <RemoveFilterButton className="ml-auto" remove={() => controller.removeFilter(filter)} />
        <FilterToolButton onClick={() => controller.toggleFilter(filter)}>
          <FontAwesomeIcon icon="chevron-up" />
        </FilterToolButton>
      </div>
      <Typeahead
        id={filter.get('field')}
        placeholder="Choose Trades"
        options={trades}
        selected={selectedTrades}
        onChange={setTrade}
        multiple
      />
    </FilterContainer>
  );
};

FilterTrade.Collapsed = function({ filter, controller }) {
  const Component = filter.get('Component');
  const value = filter.get('value');

  return (
    <CollapsedFilter filter={filter} controller={controller}>
      <span className="filter-label">Trades</span>
      <span className="filter-value">{Component.formatValue(value)}</span>
    </CollapsedFilter>
  );
};

FilterTrade.formatValue = value => {
  if (value.size === 0) return 'No Trades selected';
  return `${value.size} added`;
};

FilterTrade.formatValueReport = value => {
  if (value.size === 0) return 'No Trades selected';
  return value
    .map(val => val)
    .toArray()
    .join(', ');
};

FilterTrade.queryValue = value => {
  if (value.size === 0) return null;
  return value.toArray();
};

FilterTrade.defaultValue = List();

const FilterContainer = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  border-radius: 10px;
  padding: 13px 13px 20px 20px;
  display: flex;
  flex-direction: column;

  .title {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .label {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
`;

export function useTrades(service) {
  const [trades, setTrades] = React.useState([]);
  console.log({ trades });
  React.useEffect(() => {
    service
      .readTasks()
      .then(fromJS)
      .then(tasks =>
        tasks
          .map(task => task.get('trade'))
          .filter(trade => !!trade)
          .toSet()
      )
      .then(trades => trades.toList().toArray())
      .then(setTrades);
  }, [service]);

  return trades;
}
