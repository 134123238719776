import React from 'react';
import styled from 'styled-components';
import { fromJS, List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../theme';
import { Typeahead } from 'react-bootstrap-typeahead';
import { RemoveFilterButton, FilterToolButton } from './Report.filters.buttons';
import { useReportContext } from '../Report.context';
import { CollapsedFilter } from './Report.filters.collapsed';

export const FilterCommunity = ({ filter }) => {
  const context = useReportContext();
  const { controller, service } = context;
  const selectedCommunities = filter.get('value').toArray();
  const setCommunity = value => controller.setFilterValue(filter, List(value));
  const communities = useCommunities(service);

  return (
    <FilterContainer>
      <div className="filter-community-panel">
        <div className="d-flex mb-3 align-items-center">
          <span className="title">{filter.get('label')}</span>
          <RemoveFilterButton remove={() => controller.removeFilter(filter)} />
          <FilterToolButton onClick={() => controller.toggleFilter(filter)}>
            <FontAwesomeIcon icon="chevron-up" />
          </FilterToolButton>
        </div>
        <Typeahead
          id={filter.get('field')}
          placeholder="Choose Community"
          options={communities}
          labelKey={community => community.get('name')}
          selected={selectedCommunities}
          onChange={setCommunity}
          multiple
        />
      </div>
    </FilterContainer>
  );
};

FilterCommunity.Collapsed = function({ filter, controller }) {
  const Component = filter.get('Component');
  const value = filter.get('value');

  return (
    <CollapsedFilter filter={filter} controller={controller}>
      <span className="filter-label">Communities</span>
      <span className="filter-value">{Component.formatValue(value)}</span>
    </CollapsedFilter>
  );
};

const FilterContainer = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  border-radius: 10px;
  padding: 13px 13px 20px 20px;
  display: flex;
  flex-direction: column;

  .title {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .label {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
`;

FilterCommunity.formatValue = value => {
  if (value.size === 0) return 'No Communities selected';
  return `${value.size} added`;
};

FilterCommunity.formatValueReport = value => {
  if (value.size === 0) return 'No Communities selected';
  return value
    .map(val => val.get('name'))
    .toArray()
    .join(', ');
};

FilterCommunity.queryValue = value => {
  if (value.size === 0) return null;
  return value.map(val => val.get('_id')).toArray();
};

FilterCommunity.defaultValue = List();

export function useCommunities(service) {
  const [communities, setCommunities] = React.useState(fromJS([]));

  React.useEffect(() => {
    service
      .readCommunities()
      .then(fromJS)
      .then(communities => communities.toArray())
      .then(setCommunities);
  }, [service]);

  return communities;
}
